<template>
  <div id="rebateWithdraw">
    <div class="wrapper-main">
      <div class="wrapper-content">
        <div class="withDraw-box">
          <AuthenticationNote />
          <div class="withDraw-form">
            <div class="left">
              <el-steps direction="vertical">
                <el-step icon="red"></el-step>
                <el-step icon="blue"></el-step>
                <el-step icon="red"></el-step>
              </el-steps>
            </div>
            <div class="right">
              <h3>{{ $t('withdraw.REBATE_WITHDRAW_REQUEST') }}</h3>

              <Top
                ref="top"
                :paymentAuthConfig="paymentAuthConfig"
                :chooseWithdrawalType="chooseWithdrawalType"
                @withdrawFormChange="withdrawFormChange"
                @setCountryCode="getCountryCode"
                @setBankList="getBankList"
                @tabChange="tabChange"
                @setIsCps="getIsCps"
              ></Top>

              <component
                :is="currentComponent"
                :dialog.sync="dialog"
                :userCountryCode="userCountryCode"
                :bankList="userBankList"
                :withdrawalType="withdrawForm.withdrawalType"
                :chooseWithdrawalType="chooseWithdrawalType"
                :percentageFee="percentageFee"
                :showFee="showFee"
                :currency="withdrawForm.currency"
                @formChange="formChange"
                @changeBankComponent="countryAUandIntel"
                ref="child"
              ></component>
              <!-- <VerificationCode ref="verificationCode" namespace="IB_WITHDRAW"></VerificationCode> -->
              <div class="submit-btn-box">
                <el-button
                  class="btn-red"
                  :loading="loading"
                  :disabled="loading"
                  @click="submitForm('withdrawForm')"
                  data-testid="submit"
                >
                  {{ $t('common.keys.SUBMIT') }}
                </el-button>
              </div>
              <vDialog :show.sync="dialog.show">
                <p>{{ dialog.text }}</p>
                <div class="btn-box">
                  <el-button class="btn-red" @click="confirm" data-testid="confirm">
                    {{ $t('common.button.confirm') }}
                  </el-button>
                </div>
              </vDialog>

              <el-dialog
                @keydown.enter.native.prevent
                class="withdraw_dialog"
                :title="$t('common.withdrawChannel.limitTitle')"
                :visible.sync="visible"
                width="400px"
                center
              >
                <div style="text-align: center;">
                  {{
                    fromFunctionCode == 'updatePassword'
                      ? $t('common.withdrawChannel.limitPassword', { day: this.day, hour: this.hour })
                      : fromFunctionCode == 'changeAuthenticator'
                      ? $t('common.withdrawChannel.limitSecurityAuthenticator', { day: this.day, hour: this.hour })
                      : $t('common.withdrawChannel.limitUserLogin', { day: this.day, hour: this.hour })
                  }}
                </div>
                <div slot="footer" class="dialog-footer btn-box">
                  <el-button class="btn-blue" style="width: 100%;" @click="visible = false">{{
                    $t('common.button.iKnow')
                  }}</el-button>
                </div>
              </el-dialog>

              <VerificationDialog
                ref="verification"
                :dialogVisible.sync="verificationVisibility"
                :usedAuthTypes="paymentAuthConfig.authType"
                action="withdraw"
                @changeVisible="updateVerificationVisible"
                @confirm="confirmVerification"
              ></VerificationDialog>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationDialog
      :showConfirm="!isSubUser"
      :dialogVisible.sync="confirmationVisibility"
      @changeVisible="closeConfirmation"
      @confirm="confirmChanges"
    ></ConfirmationDialog>
  </div>
</template>

<script>
import VerificationDialog from '@/components/security/VerificationDialog.vue';
import AuthenticationNote from '@/components/security/AuthenticationNote.vue';
import Top from '@/components/rebateWithdraw/Top';
import Skrill from '@/components/rebateWithdraw/types/Skrill';
import Neteller from '@/components/rebateWithdraw/types/Neteller';
import UnionPayCps from '@/components/rebateWithdraw/types/UnionPayCps';
import Fasapay from '@/components/rebateWithdraw/types/Fasapay';
import BankTransfer from '@/components/rebateWithdraw/types/BankTransfer';
import Thailand from '@/components/rebateWithdraw/types/Thailand';
import Malaysia from '@/components/rebateWithdraw/types/Malaysia';
import Bitwallet from '@/components/rebateWithdraw/types/Bitwallet';
import Nigeria from '@/components/rebateWithdraw/types/Nigeria';
import Vietnam from '@/components/rebateWithdraw/types/Vietnam';
import Bitcoin from '@/components/rebateWithdraw/types/Bitcoin';
import USDT from '@/components/rebateWithdraw/types/USDT';
import ETH from '@/components/rebateWithdraw/types/ETH';
import USDC from '@/components/rebateWithdraw/types/USDC';
import Sticpay from '@/components/rebateWithdraw/types/Sticpay';
import Indonesian from '@/components/rebateWithdraw/types/Indonesian';
import India from '@/components/rebateWithdraw/types/India';
import Korea from '@/components/rebateWithdraw/types/Korea';
import PhpAndSA from '@/components/rebateWithdraw/types/PhpAndSA';
import Africa from '@/components/rebateWithdraw/types/Africa';
import PerfectMoney from '@/components/rebateWithdraw/types/PerfectMoney';
import Canada from '@/components/rebateWithdraw/types/Canada';
import Cps from '@/components/rebateWithdraw/types/Cps';
import MexicoAndBrazil from '@/components/rebateWithdraw/types/MexicoAndBrazil';
import { apiApplyWithdrawal, apiPaymentInfo, apiGetSecurityInfo, apiGetMultiFactorAuth } from '@/resource';
import mixin from '@/mixins';
import vDialog from '@/components/vDialog';
import { mapVerifiedAuth, mapReverifiedBody } from '@/components/security/Security.js';
import signHelper from '@/util/signinHelper';
import rounding from '@/util/rounding';
import { mapState, mapActions } from 'vuex';
import ConfirmationDialog from '@/components/security/ConfirmationDialog.vue';

export default {
  name: 'RebateWithdraw',
  components: { Top, vDialog, VerificationDialog, AuthenticationNote, ConfirmationDialog },
  mixins: [mixin],
  data() {
    return {
      day: '',
      hour: '',
      visible: false,
      fromFunctionCode: '',
      confirmationVisibility: false,
      userId: this.$store.state.common.CUID,
      accountName: this.$store.state.common.userName,
      country: null,
      form: {},
      paymentAuthConfig: {},
      withdrawForm: {},
      chooseWithdrawalType: {},
      confirmationVisibility: false,
      dialog: {
        show: false,
        text: '',
        method: ''
      },
      userCountryCode: null,
      userBankList: null,
      currentComponent: null,
      transferFlag: false,
      prevRoute: null,
      verificationVisibility: false,
      withdrawalTypeList: {
        2: BankTransfer,
        4: UnionPayCps,
        5: Cps, // Thailand, Dropdown Bankname
        6: Cps, // Malaysia, Dropdown Bankname
        7: Fasapay,
        8: Cps, // Vietnam, Dropdown Bankname
        9: Nigeria,
        24: India,
        31: Cps, // Skrill
        32: Cps, // Neteller
        34: Bitcoin,
        35: Cps, // South Korea
        36: USDT,
        37: Bitwallet,
        38: Sticpay,
        39: Cps, // Indonesia, Dropdown Bankname
        40: Cps, // Philippine, Dropdown Bankname
        42: Cps, // Sputh Africa
        50: Cps, // Uganda
        51: Cps, // Rwanda
        52: Africa, // Zambia
        53: Africa, // Congo
        54: Cps, // Cameroon
        55: Africa, // Burundi
        56: Cps, // Kenya
        57: Cps, // Ghana
        58: Cps, // Tanzania
        60: Canada,
        61: Cps,
        64: Cps, // brazil bank transfer
        75: Cps, // Ebuy
        80: ETH, // ETH
        81: USDC, // USDC
        100: Cps, //PIX
        110: Cps // Euro Instant Bank Transfer
      },
      addAccountName: [],
      percentageFee: 0,
      showFee: false,
      isCps: false,
      apiData: null,
      loading: false
    };
  },
  computed: {
    ...mapState('register', ['poiAuditStatus', 'poaAuditStatus', 'ibtPoiAuditStatus', 'ibtPoaAuditStatus']),
    amount() {
      return parseFloat(rounding(Math.round, this.withdrawForm.amount, 2));
    },
    isSubUser() {
      return this.$store.state.common.isSubUser;
    }
  },
  watch: {
    'withdrawForm.withdrawalType'(value) {
      if (value) this.promptCommission(value);
      this.country = null;
      this.$store.commit('common/setAuthHeaders', {
        etxId: '',
        eCode: '',
        ptxId: '',
        pCode: '',
        tCode: ''
      });

      if (
        this.paymentAuthConfig?.paymentChannel?.includes(value) &&
        !this.paymentAuthConfig?.authType.every(item => item.verified)
      ) {
        this.confirmationVisibility = true;
      }

      this.chooseWithdrawalType = this.$refs.top.withdrawalTypes.find(f => f.id == value);
      if (this.isCps) {
        this.currentComponent = Cps;
      } else {
        this.currentComponent = this.withdrawalTypeList[value];
      }

      // this.transferFlag =
      //   this.chooseWithdrawalType && this.chooseWithdrawalType.withdrawSubTypes.length > 0 ? true : false;
    }
  },
  //get previous path
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  async mounted() {
    this.init();
    this.$store.commit('common/setAuthHeaders', {
      etxId: '',
      eCode: '',
      ptxId: '',
      pCode: '',
      tCode: ''
    });
    await this.getAuthInfo();
    await this.getPaymentAuthConfig();
  },
  methods: {
    ...mapActions('common', ['actionUpdateId3Pass']),
    ...mapActions('register', ['actionUpdateIBTStatus', 'actionPoiAuditStatus', 'actionPoaAuditStatus']),
    async init() {
      // update id3Pass status
      await this.actionUpdateId3Pass();
      // update ibtPoa, ibtPoi status
      await this.actionUpdateIBTStatus();
      // update poi, poa status
      await this.actionPoiAuditStatus();
      await this.actionPoaAuditStatus();
    },
    async getAuthInfo() {
      await apiGetSecurityInfo().then(resp => {
        if (resp.data.code == 0) {
          const usedValue = resp.data.data;
          this.$store.commit('common/setCheckedAuthStatus', usedValue.authStatus);
        }
      });
    },
    async getPaymentAuthConfig() {
      await apiGetMultiFactorAuth('withdraw').then(resp => {
        if (resp.data.code == 0) {
          this.paymentAuthConfig = {
            authType:
              resp.data.data.authType && resp.data.data.authType.length > 0
                ? resp.data.data.authType.map(item => {
                    return {
                      type: item,
                      verified: mapVerifiedAuth(item, this.$store.state.common.checkedAuthStatus),
                      authenticated: false
                    };
                  })
                : [],
            paymentChannel: resp.data.data.paymentChannel ? resp.data.data.paymentChannel : []
          };
          this.$store.commit('common/setRequiredAuths', {
            withdraw: resp.data.data.authType
          });
        }
      }).catch(() => {
        console.log('getPaymentAuthConfig error');
      });
    },
    // fix:ACM-34977 安全验证通过且出金接口报错后 再次重复点击提交出金时安全验证确认按钮无法点击问题
    // 弹窗子组件内部usedObject是通过传入的paymentAuthConfig.authType中的authenticated过滤而来的 过滤后usedObject可能为undefined
    // 若需要再次进行安全验证 则需重置paymentAuthConfig.authType,将authenticated初始化为false
    repeatSubmitWhenSecurityAuthOpen() {
      if (this.paymentAuthConfig.authType && this.paymentAuthConfig.authType.length > 0) {
        this.paymentAuthConfig.authType = this.paymentAuthConfig.authType.map(item => {
          return {
            ...item,
            authenticated: false,
          };
        });
      }
    },

    updateVerificationVisible() {
      this.verificationVisibility = false;
    },
    confirmVerification() {
      this.verificationVisibility = false;
      this.callWithdrawApi();
    },
    getCountryCode(code) {
      this.userCountryCode = code;
    },
    getBankList(bankList) {
      this.userBankList = bankList;
    },
    withdrawFormChange(form) {
      this.withdrawForm = form;
    },
    tabChange() {
      this.currentComponent = null;
      this.withdrawForm.withdrawalType = '';
    },
    formChange(form) {
      this.form = form;
    },
    async submitForm() {
      this.loading = true;

      try {
        const top = await this.$refs?.top?.submitForm(),
          comp = await this.$refs?.child?.submitForm(),
          vm = this;
        if (top && comp) {
          vm.applyWithdraw();
        } else this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    confirm() {
      if (this.dialog.method == 'noCard') this.goToClientPortalDrawDetail();
    },
    goToClientPortalDrawDetail() {
      signHelper.signOut('backToClientPortal', '/paymentDetails');
    },
    getFormDataOfChild() {
      return this.$refs.child.getFormData && this.$refs.child.getFormData();
    },
    // 出金请求
    applyWithdraw() {
      this.loading
      let withdrawForm = { ...this.withdrawForm };
      let customFormData = this.getFormDataOfChild();

      let form = customFormData || { ...this.form };
      let accountName = this.accountName;

      // 如果出金方式为 Fasapay,则accountName为bankName，不传用户名
      if (this.withdrawForm.withdrawalType === 7) {
        accountName = form.bankName;
      }

      let apiData = {
        ...withdrawForm,
        ...form,
        qUserId: this.userId
      };
      
      if (this.isAddAccountName(form)) apiData.accountName = accountName;

      // Canada, 如果出金方式为 bank transfer international
      if (this.userCountryCode == 4575 && this.country == 2 && this.withdrawForm.withdrawalType === 2) {
        form.importantNotes = `Transit Number: ${this.$refs.child.$refs.bankChild.canadaOnly.transitNumber}, Institution Number: ${this.$refs.child.$refs.bankChild.canadaOnly.institutionNumber}, notes: ${this.form.importantNotes}`;
      }
      this.apiData = apiData;

      const needAuth = this.paymentAuthConfig?.paymentChannel?.includes(this.apiData.withdrawalType);

      if (needAuth && this.paymentAuthConfig.authType.length > 0) {
        this.verificationVisibility = true;
      } else {
        this.callWithdrawApi();
      }
    },
    callWithdrawApi() {
      apiApplyWithdrawal(this.apiData, this.token).then(async resp => {
        this.repeatSubmitWhenSecurityAuthOpen(); // fix:出金报错后 再次点击提交出金时安全验证确认按钮无法点击问题
        const limitCodes = [637, 638, 639];
        if (limitCodes.includes(resp.data.code)) {
          this.day = 'T +' + resp.data.data.limitDayNumber;
          this.hour = resp.data.data.lastHours;
          //638为修改账号引发的限制   639为修改密码引发的限制
          this.fromFunctionCode =
            resp.data.code === 638
              ? 'updateUserLogin'
              : resp.data.code === 637
              ? 'changeAuthenticator'
              : 'updatePassword';
          this.visible = true;
          await this.fetchToken()
            .then(resp => {
              this.loading = false;
            })
            .catch(resp => {
              this.loading = false;
              this.errorMessage(this.$t('withdraw.default.tokenExpired'));
            });
          await this.getPaymentAuthConfig();
          return;
        } else if (resp.data.code === 1201 || resp.data.code === 1202) {
          // re-calling anti-reuse token
          this.fetchToken()
            .then(resp => {
              this.loading = false;
            })
            .catch(resp => {
              this.loading = false;
              this.errorMessage(this.$t('withdraw.default.tokenExpired'));
            });
          await this.getPaymentAuthConfig();
          this.paymentAuthConfig.authType = mapReverifiedBody('withdraw', resp);
          const allVerified = this.paymentAuthConfig.authType.every(item => item.verified == true);
          if (!allVerified) {
            this.confirmationVisibility = true;
          } else {
            this.verificationVisibility = true;
          }
        } else if (resp.data.code === 0) {
          this.loading = false;
          this.$message.success(this.$t('withdraw.YourPaymentIsBeingProcessed'));
          this.bus.$emit('updateWithdrawHistory', true);
          this.$store.commit('common/setAuthHeaders', {
            etxId: '',
            eCode: '',
            ptxId: '',
            pCode: '',
            tCode: ''
          });
          this.$nextTick(function() {
            this.$router.push(this.prevRoute && this.prevRoute.path != '/' ? this.prevRoute.name : 'home');
          });
        } else if (resp.data.code === 551) {
          window.location.reload();
        } else {
          this.errorMessage(this.$t('withdraw.default.failed'));
          // re-calling anti-reuse token
          this.fetchToken()
            .then(resp => {
              this.loading = false;
            })
            .catch(resp => {
              this.loading = false;
              this.errorMessage(this.$t('withdraw.default.tokenExpired'));
            });
        }
      }).catch(() => {
        this.repeatSubmitWhenSecurityAuthOpen(); // fix:出金报错后 再次点击提交出金时安全验证确认按钮无法点击问题
      });
    },
    countryAUandIntel(value) {
      this.country = parseInt(value);
    },
    isAddAccountName(form) {
      return form.cpsType ? this.addAccountName.includes(this.withdrawForm.withdrawalType) : true;
    },
    promptCommission(type) {
      apiPaymentInfo(type).then(resp => {
        this.percentageFee = resp.data.percentage_fee;
        this.showFee = resp.data.show_hint;
      });
    },
    getIsCps(val) {
      this.isCps = val;
    },
    closeConfirmation() {
      this.confirmationVisibility = false;
      this.currentComponent = null;
      this.withdrawForm.withdrawalType = null;
    },
    confirmChanges() {
      this.confirmationVisibility = false;
      signHelper.signOut('backToClientPortal', '/securityManagement');
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/pages/rebateWithdraw.scss';
.withdraw_dialog {
  /deep/ .el-dialog__header {
    padding: 61px 61px 0 61px;
  }
  /deep/ .el-dialog__body {
    padding: 0 61px;
    margin-top: 42px;
    margin-bottom: 48px;
  }
  /deep/ .el-dialog__footer {
    padding: 0 61px 61px 61px;
  }
  /deep/ .el-dialog__headerbtn {
    display: inline-block;
    font-size: 20px;
    .el-dialog__close {
      font-weight: bolder;
      color: $black;
    }
  }
  .word_break {
    word-break: normal;
  }
}
</style>
